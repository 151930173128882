<template>
    <pagination title="Quality Assessments" :response="response" :entities="qas" :checkSize="false" :has-entities="true" header-colour-class="bg-transparent" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Quality Assessment
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Date Of Works</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold"></th>

        </template>
        <template v-slot:items>
            <tr v-for="qa in qas">
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium sm:pl-6">
                    <div>
                        {{qa['name']}}
                    </div>
                </td>
                <td class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <div>
                        {{qa['date_of_works']}}
                    </div>
                </td>
                <th class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                </th>
            </tr>
            <tr v-for="(item, index) in items">
                <td colspan="2">
                    <div>
                        <property-or-autocomplete-field ref="quality_assessment_id"
                            :action="addQaAction" fieldId="quality_assessment_id"
                            propertyKey="quality_assessment_id"
                            v-model="items[index]['quality_assessment_id']"
                            :showLabel="false"></property-or-autocomplete-field>
                    </div>
                </td>
                <th class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium">
                    <span class="cursor-pointer font-normal underline text-gray-400" @click="removeItem();">remove</span>
                </th>
            </tr>
            <tr v-if="addQaAction && editing">
                <td colspan="3" class="whitespace-normal py-4 pl-4 pr-3 text-sm font-medium" >
                    <div class="flex justify-center">
                        <custom-button @click.native="addNewItem">
                            + Add Quality Assessment
                        </custom-button>
                    </div>
                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas.vue";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
export default {
  name: 'VoucherQaPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          items: []
      }
    },
    components: {
        PropertyOrAutocompleteField,
        NewPaymentVoucherQas,
        CustomButton,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        qas() {
            if (!this.voucher) {
                return null;
            }
            return this.voucher.properties.get('qas');
        },
        addQaAction(){
            return this.voucher.actions.filter(action => action.name === 'add-qa').first();
        }
    },
  props: {
    voucher: {},
      response: {
        type: Object,
          default: null
      },
      editing: {
          type: Boolean,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        addNewItem() {
            this.items.push({'quality_assessment_id': null });
            this.$emit('change');
        },
        newItemActionData(index) {
            if (!this.addQaAction.fields) {
                return {};
            }
            var payload = {};
            this.addQaAction.fields.forEach(function (field) {
                if (field.value !== undefined) {
                    payload[field.name] = field.value;
                }
            }, this);
            return payload;
        },
        removeItem(index) {
          this.items.splice(index, 1);
        },
        saveQas(){
            let updateItemPromises = [];
            let that = this;
            this.items.forEach(function (promise) {
                updateItemPromises.push(that.addQaAction.perform(promise));
            })

            return Promise.all(updateItemPromises);
        }
    }
}
</script>
