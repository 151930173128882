<template>
    <pagination title="Voucher Preview" :response="response" :entities="voucher" :checkSize="false" :has-entities="true" header-colour-class="bg-transparent">
        <template v-slot:additional-buttons>
            <slot name="additional-buttons"></slot>
        </template>
        <template v-slot:headers>
            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold sm:pl-6">Current Earnings
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Qty</th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold">Value</th>

        </template>
        <template v-slot:items>
            <template v-if="voucher.properties.get('hours') > 0 && voucher.properties.get('payment_type') === 'hourly_rate'">
                <tr>
                    <td>Day Work:</td>
                    <td class="text-right">{{voucher.properties.get('hours')}}</td>
                    <td class="text-right">{{voucher.properties.get('total_hourly_payment')}}</td>
                </tr>
            </template>
            <template v-if="voucher.properties.get('hours') > 0 && voucher.properties.get('payment_type') !== 'hourly_rate'">
                <tr>
                    <td>Extra/Overtime:</td>
                    <td class="text-right">{{voucher.properties.get('hours')}}</td>
                    <td class="text-right">{{voucher.properties.get('total_overtime_payment')}}</td>
                </tr>
            </template>
            <template v-for="qa in voucher.properties.get('qas')">
                <tr>
                    <td colspan="3" class="font-bold">{{qa['name']}}</td>
                </tr>
                <tr v-if="question.quantity > 0" v-for="question in qa.questions">
                    <td>{{question.name}}:</td>
                    <td class="text-right">{{question.quantity}}</td>
                    <td class="text-right">{{question.total_value}}</td>
                </tr>
            </template>
            <template v-for="dayPay in voucher.properties.get('day_pay_requests')">
                <tr>
                    <td>{{dayPay.name}}:</td>
                    <td class="text-right">{{dayPay.hours}}</td>
                    <td class="text-right">{{dayPay.total}}</td>
                </tr>
            </template>
            <tr class="text-right">
                <th colspan="2">Gross :</th>
                <td >{{voucher.properties.get('grand_total')}}</td>
            </tr>
            <tr class="text-right">
                <th colspan="2">Retention :</th>
                <td>{{voucher.properties.get('total_retention_held_back')}}</td>
            </tr>
            <tr class="text-right">
                <th colspan="2">Retention Return :</th>
                <td>{{voucher.properties.get('total_retention')}}</td>
            </tr>
            <tr class="text-right">
                <th colspan="2">Tax(20%) :</th>
                <td>{{voucher.properties.get('total_tax')}}</td>
            </tr>
            <tr class="text-right">
                <th colspan="2">To Pay :</th>
                <td >{{voucher.properties.get('total_to_pay')}}</td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import HrSinglePersonPagination from "@/v3/components/pagination/HrSinglePersonPagination.vue";
import StatusBadge from "@/components/StatusBadge.vue";
import FlagOrderAction from "@/v2/components/FlagOrderAction.vue";
import dayjs from "dayjs";
import Siren from "super-siren";
import DownloadPdfIconWithLink from "@/components/DownloadPdfIconWithLink.vue";
import CustomButton from "@/v3/Buttons/CustomButton.vue";
import NewPaymentVoucherQas from "@/components/NewPaymentVoucherQas.vue";
import PropertyOrAutocompleteField from "@/components/PropertyOrAutocompleteField.vue";
export default {
  name: 'VoucherPreviewPagination',
    data(){
      return {
        viewUser: false,
          details: null,
          detailsOpen: false,
          items: []
      }
    },
    components: {
        PropertyOrAutocompleteField,
        NewPaymentVoucherQas,
        CustomButton,
        DownloadPdfIconWithLink, FlagOrderAction, StatusBadge, HrSinglePersonPagination, Loading, Pagination},
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser",
        }),
        initials() {
            if (this.details && this.details.properties['name']) {
                return this.details.properties['name']
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        filterAction(){
            return this.response.body.actions.filter(action => action.name === 'filter').first();
        },
        qas() {
            if (!this.voucher) {
                return null;
            }
            return this.voucher.properties.get('qas');
        },
        addQaAction(){
            return this.voucher.actions.filter(action => action.name === 'add-qa').first();
        }
    },
  props: {
    voucher: {},
      response: {
        type: Object,
          default: null
      },
      editing: {
          type: Boolean,
          default: null
      }
  },
    methods:{
      openDetails(entity){
          this.$emit('setLoading');
          this.details = false;
          this.viewUser = true;
          this.getDetails(entity);
      },
        getDetails(entity){
            let selfLink = entity.entity.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            Siren.Client.addHeader(
                "Authorization",
                `Bearer ${this.accessToken}`
            );
            Siren.get(process.env.VUE_APP_API_URL + '/api' + parts[1]).then(response => {
                this.$emit('setDetails', response.body);
            }).catch(function(){

            });
        },
        addNewItem() {
            this.items.push({'quality_assessment_id': null });
            this.$emit('change');
        },
        newItemActionData(index) {
            if (!this.addQaAction.fields) {
                return {};
            }
            var payload = {};
            this.addQaAction.fields.forEach(function (field) {
                if (field.value !== undefined) {
                    payload[field.name] = field.value;
                }
            }, this);
            return payload;
        },
        removeItem(index) {
          this.items.splice(index, 1);
        },
        saveQas(){
            let updateItemPromises = [];
            let that = this;
            this.items.forEach(function (promise) {
                updateItemPromises.push(that.addQaAction.perform(promise));
            })

            return Promise.all(updateItemPromises);
        }
    }
}
</script>
