<template>
    <pagination title="Details" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Operative
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="subcontractor_name"
                        :action="editing ? action: null"
                        fieldId="operative_id"
                        :showLabel="false"
                        v-model="updateActionData['operative_id']">
                    </property-or-field>
                    <div v-show="errors.has('operative_id')" class="text-red-600">{{ errors.first('operative_id') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Contract
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="contract_details"
                        :action="editing ? action: null"
                        fieldId="contract_id"
                        :showLabel="false"
                        v-model="updateActionData['contract_id']">
                    </property-or-field>
                    <div v-show="errors.has('contract_id')" class="text-red-600">{{ errors.first('contract_id') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Additional Day Work Hours
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="hours"
                        :action="editing ? action: null"
                        fieldId="hours"
                        :showLabel="false"
                        v-model="updateActionData['hours']">
                    </property-or-field>
                    <div v-show="errors.has('hours')" class="text-red-600">{{ errors.first('hours') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'VoucherDetailsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
